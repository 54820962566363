






































































































































import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { ReferralViewModel } from '../viemmodel/referral-viewmodel'

@Observer
@Component
export default class extends Vue {
  @Inject() vm!: ReferralViewModel

  page = 1

  currentIndex = 1

  get cols() {
    const width = this.$vuetify.breakpoint.width
    if (width < 737) {
      return 12
    } else if (width < 1280) {
      return 6
    } else {
      return ''
    }
  }

  getStatus(completed) {
    const mobileAndTablet = this.$vuetify.breakpoint.mdAndDown
    if (!completed) {
      return mobileAndTablet ? 'Registered' : 'Registered account'
    } else {
      return mobileAndTablet ? 'Completed' : 'Completed quest'
    }
  }
}
